import React, { useEffect, useRef } from "react"

import styled from "styled-components"
import { gsap } from "gsap"
import { useAppContext } from "../../context/AppContext"

function TextLabels(props) {
  const { homeTextLight } = useAppContext()
  const timeline = useRef()
  const label1Ref = useRef()
  const label2Ref = useRef()
  const label3Ref = useRef()

  useEffect(() => {
    timeline.current = gsap.timeline({ delay: 1 })

    timeline.current.from(
      label1Ref.current,
      {
        duration: 0.75,
        x: "100%",
        ease: "power3.out",
      },
      `in`
    )

    timeline.current.from(
      label2Ref.current,
      {
        duration: 0.75,
        y: "100%",
        ease: "power3.out",
      },
      `in+=${0.3}`
    )

    timeline.current.from(
      label3Ref.current,
      {
        duration: 0.75,
        x: "-100%",
        ease: "power3.out",
      },
      `in+=${0.6}`
    )
    return () => {
      timeline.current.kill()
    }
  }, [])

  const style = homeTextLight ? { color: "#fff" } : { color: "#260102" }

  return (
    <Container>
      <Text style={style}>
        <div ref={label1Ref}>Los Angeles</div>
      </Text>

      <Text style={style}>
        <div ref={label2Ref}>Ja Tecson</div>
      </Text>

      <Text style={style}>
        <div ref={label3Ref}>Photographer</div>
      </Text>
    </Container>
  )
}

export default TextLabels

const PAD_SM = 15
const PAD = 30

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  user-select: none;
  pointer-events: none;
`

const Text = styled.div`
  position: absolute;

  > div {
    font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 9px;
    color: currentColor;
    letter-spacing: 4.33px;
    text-align: center;
    text-transform: uppercase;
    transition: color 0.7 ease-out;

    @media (min-width: 500px) {
      font-size: 10px;
      letter-spacing: 4.33px;
    }
  }

  overflow: hidden;

  &:first-child {
    writing-mode: vertical-lr;
    top: calc(50% - 20px);
    left: ${PAD_SM}px;
    transform: translate(0, -50%) rotate(180deg);

    @media (min-width: 500px) {
      left: ${PAD}px;
    }
  }

  &:nth-child(2) {
    left: 50%;
    bottom: ${PAD_SM}px;
    transform: translate(-50%, 0);

    @media (min-width: 500px) {
      bottom: ${PAD}px;
    }
  }

  &:last-child {
    writing-mode: vertical-lr;
    top: calc(50% - 15px);
    right: ${PAD_SM}px;
    transform: translate(0, -50%);
    transform-origin: center;

    @media (min-width: 500px) {
      right: ${PAD}px;
    }
  }
`
