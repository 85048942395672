import React, { forwardRef, memo } from "react"

import styled, { css } from "styled-components/macro"
import useIsSafari from "../../hooks/useIsSafari"

function SvgMaskImage({ fluid, ...props }, svgRef) {
  const isSafari = useIsSafari()
  const src = isSafari ? fluid?.src : fluid?.srcWebp
  return (
    <Container>
      <svg viewBox="0 0 800 585.3" ref={svgRef} {...props}>
        <defs>
          <clipPath id="mask" clipPathUnits="userSpaceOnUse">
            <path d="M457.51 0L230.03 388.5H0l115.02 196.8h227.47l115.02-194.25L572.52 585.3H800L457.51 0z" />
          </clipPath>
        </defs>

        <image
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin slice"
          href={src}
          clipPath="url(#mask)"
        />
      </svg>
    </Container>
  )
}

const ForwardRef = forwardRef(SvgMaskImage)
const MemoForwardRef = memo(ForwardRef)
export default MemoForwardRef

const Container = styled.div(css`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  background-color: #fff;

  svg {
    width: 100%;
    height: 100%;

    min-height: 100px;

    max-width: calc(100% - 90px);
    max-height: calc(100% - 200px);

    transform: translateY(-27px);

    @media (min-width: 500px) {
      max-width: calc(100% - 140px);
    }

    @media (min-width: 920px) {
      max-width: 775px;
    }
  }
`)
