import React from "react"
import { FullsizePicture } from "react-responsive-picture"

import styled from "styled-components/macro"
import useIsSafari from "../../hooks/useIsSafari"

export default function FullScreenImage({ file, fluid, ...props }) {
  const isSafari = useIsSafari()
  const src = isSafari ? fluid?.srcSet : fluid?.srcSetWebp
  const size = file?.details?.image || {}
  return (
    <Container>
      <Image src={src} sizes={fluid?.sizes} {...size} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translateY(-74px);
  height: calc(100% + 74px) !important;

  @media (max-width: 400px) {
    transform: translateY(-64px);
    height: calc(100% + 64px) !important;
  }

  @media (max-width: 360px) {
    transform: translateY(-54px);
    height: calc(100% + 54px) !important;
  }
`

const Image = styled(FullsizePicture)``
