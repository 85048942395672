import React, { useEffect, useRef } from "react"
import createClass from "create-react-class"
import ProjectImage from "../components/ProjectImage.js"
import { useToggleOnce } from "../../hooks/useToggleHooks"
import useAnimateOnMount from "../../hooks/useAnimateOnMount"

function ProjectsGrid({ classList, className, time, ...props }) {
  const delay = useRef()
  const ref = useRef()
  const [delayComplete, setDelayComplete] = useToggleOnce(false)

  useAnimateOnMount(ref)

  useEffect(() => {
    delay.current = setTimeout(setDelayComplete, time)
    return () => {
      if (delay.current) clearTimeout(delay.current)
    }
  }, [time, delay, setDelayComplete])

  return (
    <div className={classList || className} ref={ref}>
      <ProjectsGridContent load={delayComplete} {...props} />
    </div>
  )
}

ProjectsGrid.defaultProps = {
  time: 0,
}

const ProjectsGridContent = createClass({
  render() {
    const { load, sets, pagePath } = this.props
    const keys = Object.values(sets)

    return (
      <>
        {keys.map((item, i) => (
          <ProjectImage
            key={`${item.id}${i}`}
            load={load}
            image={item.featuredImage}
            path={item.path || pagePath}
            {...item}
          />
        ))}
      </>
    )
  },
})

export default ProjectsGrid
