import { useState, useCallback, useRef, useEffect } from "react"

export function useToggle(initial) {
  const [value, setter] = useState(initial)
  return [value, () => setter(!value), () => setter(true), () => setter(false)]
}

export function useToggleOnce(initial) {
  let [value, toggle] = useToggle(initial)
  let hasRun = false
  const cb = useCallback(() => {
    if (hasRun) {
      return
    }
    hasRun = true // eslint-disable-line
    toggle()
  }, [])
  return [value, cb]
}

export function useToggleValues(initial, values) {
  const [value, setter] = useState(initial)
  const [a, b] = values
  return [
    value,
    () => setter(value === a ? b : a),
    () => setter(a),
    () => setter(b),
  ]
}

export function useValues(initial, values) {
  const [value, setter] = useState(initial)
  return [value, ...values.map(value => () => setter(value))]
}

export function useRange(initial, [min, max]) {
  const [value, setter] = useState(initial)
  const numbers = []
  for (let i = min; i < max; i++) {
    numbers.push(i)
  }
  return [value, ...numbers.map(value => () => setter(value))]
}

export function usePrevious(value, startWithInitial = false) {
  const ref = useRef(startWithInitial ? value : undefined)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function useResolver(initial, resolver) {
  const [value, setter] = useState(initial)
  return [value, (...args) => setter(resolver(...args))]
}

export function useAsyncResolver(initial, resolver) {
  const [value, setter] = useState(initial)
  return [
    value,
    (...args) => {
      resolver(...args).then(value => setter(value))
    },
  ]
}
