import React, { useEffect } from "react"
import createClass from "create-react-class"
import Reflux from "reflux"

import ProjectsGrid from "../components/ProjectsGrid.js"
import Slides from "../components/Slides.js"
import NavSpacer from "../../components/common/NavSpacer"

import { get } from "lodash"
import { useAppContext } from "../../context/AppContext"

import HomeActions from "../actions/HomeActions.js"

const HomeContent = ({ filter, grey, time, ...props }) => {
  const {
    setLogoGrey,
    setLogoColored,
    setHomeTextLight,
    setHomeTextDark,
  } = useAppContext()

  let imagesSet = get(props, "contentfulImagesSection.sets") || []
  imagesSet = imagesSet.map(item => ({ ...item, path: "images" }))

  let projectsSet = get(props, "contentfulProjectSection.sets") || []
  projectsSet = projectsSet.map(item => ({ ...item, path: "projects" }))

  const sets = [...imagesSet, ...projectsSet]

  const images = get(props, "contentfulHome.slides") || []
  const logos = get(props, "contentfulHome.logos") || []
  const slideDuration = get(props, "contentfulHome.slideDuration") || 6

  useEffect(() => {
    if (grey) {
      setLogoGrey()
    } else {
      setLogoColored()
    }
  }, [grey, setLogoGrey, setLogoColored])

  return (
    <HomeDisplay
      slideDuration={slideDuration}
      sets={sets}
      logos={logos}
      images={images}
      time={time}
      setHomeTextDark={setHomeTextDark}
      setHomeTextLight={setHomeTextLight}
    />
  )
}

export default HomeContent

const HomeDisplay = createClass({
  mixins: [Reflux.ListenerMixin],

  componentDidMount() {
    HomeActions.on()
  },

  componentWillUnmount() {
    HomeActions.off()
  },

  render() {
    const {
      slideDuration,
      images,
      logos,
      sets,
      time,
      setHomeTextDark,
      setHomeTextLight,
    } = this.props
    const isHome = !!images?.length ? true : false

    return (
      <>
        {isHome ? (
          <Slides
            slideDuration={slideDuration}
            logos={logos}
            images={images}
            setHomeTextDark={setHomeTextDark}
            setHomeTextLight={setHomeTextLight}
            sets={sets}
          />
        ) : (
          <NavSpacer />
        )}

        <div className="projects-images">
          {!!sets && (
            <ProjectsGrid time={time} sets={sets} classList="projects" />
          )}
        </div>
      </>
    )
  },
})
