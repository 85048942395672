import React from "react"
import { Picture } from "react-responsive-picture"

import styled from "styled-components/macro"
import useIsSafari from "../../hooks/useIsSafari"

export default function ImageWithMargin({ file, fluid }) {
  const isSafari = useIsSafari()
  const src = isSafari ? fluid?.srcSet : fluid?.srcSetWebp
  const size = file?.details?.image || {}

  return (
    <Container>
      <Image src={src} sizes={fluid?.sizes} {...size} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled(Picture)`
  max-width: calc(100% - 90px);
  max-height: calc(100% - 90px);

  object-fit: contain;

  transform: translateY(-27px);

  @media (min-width: 500px) {
    max-width: calc(100% - 140px);
    max-height: calc(100% - 160px);
  }
`
