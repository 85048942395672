import React, { forwardRef, memo } from "react"

import styled, { css } from "styled-components/macro"

function LogoSlide({ image, ...props }, ref) {
  return (
    <Container ref={ref}>
      {image?.file?.url && <img src={image.file.url} alt="" />}
    </Container>
  )
}

const ForwardRef = forwardRef(LogoSlide)
const MemoForwardRef = memo(ForwardRef)
export default MemoForwardRef

const Container = styled.div(css`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  background-color: #fff;

  img {
    width: 100%;
    height: 100%;

    min-height: 100px;

    max-width: calc(100% - 90px);
    max-height: calc(100% - 200px);

    transform: translateY(-15px);

    @media (min-width: 500px) {
      max-width: calc(100% - 140px);
    }

    @media (min-width: 920px) {
      max-width: 775px;
    }
  }
`)
