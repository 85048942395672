import React, { useCallback, useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"

import Swiper from "swiper"

import SvgMaskImage from "../../components/home/SvgMaskImage"
import FullScreenImage from "../../components/home/FullScreenImage"
import JaTecsonPhotographer from "../../components/home/JaTecsonPhotographer"
import LogoSlide from "../../components/home/LogoSlide"
import TextLabels from "../../components/home/TextLabels"
import { TweenMax, gsap } from "gsap"
import { Link } from "gatsby"

import styled, { css } from "styled-components/macro"
import useWindowSize from "../../hooks/useWindowSize"
import useSSR from "use-ssr"
import useIsSafari from "../../hooks/useIsSafari"
import { isUndefined, sample, keyBy } from "lodash"
import ImageWithMargin from "../home/ImageWithMargin"

function Slides({
  slideDuration,
  images,
  logos,
  setHomeTextLight,
  setHomeTextDark,
  sets,
}) {
  const { isBrowser } = useSSR()
  const isSafari = useIsSafari()
  const targetRef = useRef()
  const contentRef = useRef()
  const swiperRef = useRef()
  const logoRef = useRef()
  const { height: vh, width: vw } = useWindowSize()
  const ready = isBrowser && !isUndefined(isSafari)
  const [textComplete, setTextComplete] = useState(false)
  const [small, setSmall] = useState()
  const onComplete = () => setTextComplete(true)
  const logo = sample(logos)
  const clientX = useRef(0)
  const keys = keyBy(sets, "contentfulid")

  useEffect(() => {
    return () => {
      setHomeTextDark()
    }
  }, [setHomeTextDark])

  useEffect(() => {
    if (textComplete) {
      TweenMax.to(contentRef.current, {
        duration: 0.75,
        x: small ? "-100vw" : "-60vw",
        ease: "power3.out",
        onComplete: () => {
          TweenMax.set(contentRef.current, {
            x: small ? "-100vw" : "-60vw",
          })
        },
      })

      TweenMax.to(logoRef.current, {
        duration: 0.75,
        alpha: 0,
        ease: "power2.out",
      })

      TweenMax.to(targetRef.current, {
        duration: 2,
        opacity: 1,
        ease: "power1.out",
      })

      if (swiperRef.current?.autoplay) {
        swiperRef.current.autoplay.start()
        swiperRef.current.allowSlideNext = true
        swiperRef.current.allowSlidePrev = true
      }
    }

    return () => {
      gsap.killTweensOf(contentRef.current)
      gsap.killTweensOf(targetRef.current)
      gsap.killTweensOf(logoRef.current)
    }
  }, [textComplete])

  useEffect(() => {
    const newSmall = vw < 499
    if (newSmall !== small) setSmall(newSmall)
    if (textComplete) {
      TweenMax.set(contentRef.current, {
        x: small ? "-100vw" : "-60vw",
      })
    }
  }, [small, vw, setSmall])

  useEffect(() => {
    if (ready) {
      swiperRef.current = new Swiper(targetRef.current, {
        autoplay: {
          delay: slideDuration * 1000,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        allowSlideNext: false,
        allowSlidePrev: false,
        grabCursor: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
      })
      swiperRef.current.autoplay.stop()
      swiperRef.current.on("slideChange", () => {
        const index = swiperRef.current.realIndex
        const lightText = images[index]?.lightText ? true : false
        if (lightText) {
          setHomeTextLight()
        } else {
          setHomeTextDark()
        }
      })

      swiperRef.current.on("touchStart", e => {
        clientX.current = e.clientX
      })

      swiperRef.current.on("touchEnd", e => {
        const sensitivity = 5
        const x = e.pageX
        if (Math.abs(x - clientX.current) > sensitivity) {
          if (x > clientX.current) {
            // prev
            const isFirst = swiperRef.current.realIndex === 0
            if (isFirst) swiperRef.current.slideTo(images.length)
          } else {
            // next
            const isLast = swiperRef.current.realIndex === images.length
            if (isLast) swiperRef.current.slideTo(0)
          }
          clientX.current = x
        }
      })
    }

    TweenMax.to(targetRef.current, {
      duration: 2,
      opacity: 0.5,
      ease: "power1.out",
      delay: 1,
    })

    return () => {
      if (swiperRef.current) swiperRef.current.destroy()
    }
  }, [targetRef, swiperRef, ready])

  const handleClick = useCallback(() => {
    if (!textComplete) {
      setTextComplete(true)
    }
  }, [textComplete, setTextComplete])

  const getLink = useCallback(
    id => {
      const { path } = keys[id]
      return `/set/${path}/${id}`
    },
    [sets]
  )

  return (
    <>
      <Mask ref={contentRef} onClick={handleClick}>
        <JaTecsonPhotographer
          ref={logoRef}
          onComplete={textComplete ? null : onComplete}
        />

        <Container ref={targetRef} style={{ height: vh }} onClick={handleClick}>
          <Content className="swiper-wrapper">
            {ready && (
              <>
                {images.map(
                  ({ fillScreen, mask, lightText, image, ...props }, i) => {
                    const linkId = props?.link?.contentfulid
                    const content = (
                      <>
                        {fillScreen ? (
                          <FullScreenImage {...image} />
                        ) : (
                          <>
                            {mask ? (
                              <SvgMaskImage {...image} />
                            ) : (
                              <ImageWithMargin {...image} />
                            )}
                          </>
                        )}
                      </>
                    )
                    return linkId ? (
                      <SlideLink
                        key={`img${i}`}
                        to={getLink(linkId)}
                        className="swiper-slide"
                        style={{
                          pointerEvents: textComplete ? "auto" : "none",
                        }}
                      >
                        {content}
                      </SlideLink>
                    ) : (
                      <Slide key={`img${i}`} className="swiper-slide">
                        {content}
                      </Slide>
                    )
                  }
                )}
                <Slide key={`logo`} className="swiper-slide">
                  <LogoSlide {...logo} />
                </Slide>
              </>
            )}
          </Content>
          {textComplete && <TextLabels />}
        </Container>
      </Mask>
    </>
  )
}

Slides.propTypes = { images: PropTypes.array }

export default Slides

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100vw;
  min-height: 55vh;
  max-height: 55vh;
  opacity: 0;

  @media (min-width: 500px) {
    min-height: 80vh;
    max-height: 80vh;
  }
`

const Mask = styled.div(
  () => css`
    display: flex;
    flex-wrap: nowrap;
    flex: 1;

    position: relative;
    width: 100%;

    padding-top: 74px;

    @media (max-width: 400px) {
      padding-top: 64px;
    }

    @media (max-width: 360px) {
      padding-top: 54px;
    }
  `
)

const Content = styled.div(
  () => css`
    display: flex;
    position: relative;
    width: 100vw;
    flex: 1;
    position: relative;
  `
)

const sharedSlide = () => css`
  width: 100%;
  height: 100%;

  pointer-events: none;
  user-select: none;

  * {
    pointer-events: none;
    user-select: none;
  }

  > * {
    width: 100%;
    height: 100%;
  }
`

const Slide = styled.div`
  ${sharedSlide};
`

const SlideLink = styled(Link)`
  ${sharedSlide};
`
