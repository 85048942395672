import React from "react"
import createClass from "create-react-class"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ProjectImage = createClass({
  render() {
    const { contentfulid, id, path, load } = this.props
    const fluid = this.props?.featuredImage?.fluid
    const size = this.props?.featuredImage?.file?.details?.image || {}

    return (
      <div className="project-item">
        <Link to={`/set/${path}/${id || contentfulid}`}>
          <div className="p-container">
            {load && fluid && (
              <Img
                ref={i => (this.bgImg = i)}
                className="image p-bg-img p-bg-img-loaded"
                fluid={fluid}
                objectFit="cover"
                {...size}
              />
            )}
            <div className="p-label-container fade-in2">
              <div className="p-label">
                <div>{path}</div>
                <div className="sm-rule">
                  <div className="sm-inside" />
                </div>
                <div className="p-title">
                  <span>{this.props.title}</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  },
})

export default ProjectImage
