import React, { forwardRef, memo, useRef, useEffect, useState } from "react"

import { gsap } from "gsap"

import styled from "styled-components"

const duration = 0.5

const polyStart = "polygon(0 0, 100% 0, 100% 0, 0 0)"
const poly = "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
const polyEnd = "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)"

function JaTecsonPhotographer({ onComplete, ...props }, svgRef) {
  const timeline = useRef()
  const jaRef = useRef()
  const tecsonRef = useRef()
  const photoRef = useRef()
  const grapherRef = useRef()
  const delayRef = useRef()
  const [step, setStep] = useState(0)

  useEffect(() => {
    timeline.current = gsap.timeline({
      delay: 0,
      onComplete,
    })

    timeline.current
      .to(delayRef.current, { duration: 0, opacity: 0 }, "1")
      .call(() => setStep(1))

    timeline.current
      .to(delayRef.current, { duration: 0, opacity: 0 }, `1+=${0.1}`)
      .call(() => setStep(2))

    timeline.current.to(delayRef.current, { duration: 2, opacity: 0 }, "2")

    timeline.current
      .to(
        delayRef.current,
        {
          duration,
        },
        `3`
      )
      .call(() => setStep(3))

    timeline.current
      .to(
        delayRef.current,
        {
          duration,
        },
        `3+=${0.1}`
      )
      .call(() => setStep(4))

    timeline.current.to(
      tecsonRef.current,
      {
        duration,
        opacity: 0.5,
      },
      `4`
    )

    timeline.current
      .to(
        delayRef.current,
        {
          duration,
        },
        `5`
      )
      .call(() => setStep(5))

    timeline.current
      .to(
        delayRef.current,
        {
          duration,
        },
        `5+=${0.1}`
      )
      .call(() => setStep(6))

    timeline.current.to(
      delayRef.current,
      {
        duration: 1,
      },
      `6`
    )
  }, [])

  return (
    <Container ref={svgRef} {...props}>
      <div ref={delayRef} style={{ position: "absolute" }} />
      <Content>
        <div>
          <Text
            css={{
              clipPath: step === 0 ? polyStart : step <= 2 ? poly : polyEnd,
              opacity: step === 0 ? 0 : 1,
            }}
          >
            <div ref={jaRef}>JA</div>
          </Text>
          <Text
            css={{
              clipPath: step <= 1 ? polyStart : step < 5 ? poly : polyEnd,
              opacity: step === 0 ? 0 : 1,
            }}
          >
            <div ref={tecsonRef}>TECSON</div>
          </Text>
        </div>

        <div>
          <Text
            css={{
              clipPath: step < 4 ? polyStart : poly,
              opacity: step < 4 ? 0 : 1,
            }}
          >
            <div ref={photoRef}>PHOTO-</div>
          </Text>
          <Text
            css={{
              clipPath: step < 5 ? polyStart : poly,
              opacity: step < 5 ? 0 : 1,
            }}
          >
            <div ref={grapherRef}>GRAPHER</div>
          </Text>
        </div>
      </Content>
    </Container>
  )
}

const ForwardRef = forwardRef(JaTecsonPhotographer)
const MemoForwardRef = memo(ForwardRef)
export default MemoForwardRef

const Container = styled.div`
  top: 0;
  left: 0;
  min-width: 100vw;
  padding: 10%;
  flex: 1;
  z-index: 2;

  min-height: 55vh;
  max-height: 55vh;

  transform: translateY(-8px);

  @media (min-width: 500px) {
    min-width: 60vw;
    min-height: 80vh;
    max-height: 80vh;

    transform: translateY(0);
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  top: 0;
  left: 0;
  min-width: 50vw;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
`

const Text = styled.div`
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: clip-path 0.7s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  > div {
    color: #343435;
    font-size: 8.5vw;
    font-family: "DinBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0.25em;
    line-height: 1em;
    text-decoration: uppercase;

    @media (max-width: 500px) {
      font-size: 13vw;
    }
  }
`
