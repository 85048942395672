import { useEffect } from "react"
import { TweenMax, gsap } from "gsap/all"
import ease from "../components/constants/ease"

export default function useAnimateOnMount(ref) {
  useEffect(() => {
    if (!ref.current) return

    TweenMax.set(ref.current, { alpha: 0, y: "+=50" })

    let tl = gsap.timeline({ delay: 0 })
    tl.to(
      ref.current,

      {
        duration: 1.25,
        alpha: 1,
        ease: ease("outQuad"),
      },
      "start"
    )
    tl.to(
      ref.current,
      {
        duration: 1,
        y: "0",
        ease: ease("outExpo"),
      },
      "start"
    )
  }, [])
}
