let Reflux = require('reflux');

let HomeActions = Reflux.createActions([
    'next',
    'loading',
    'ready',
    'on',
    'off',
    'animationComplete'
]);

export default HomeActions;
